import { useState, useEffect } from "react";
import CartContext from "../../../context/CartContext";
import { type } from "@testing-library/user-event/dist/type";
import { Cart } from "../../../models/OrderModel";

const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getCart = () => {
        return cart;
    };

    // Fungsi untuk menambahkan produk ke cart

    const addToCart = (
        product_id,
        name,
        price,
        quantity,
        variant,
        variations,
        tax_total
    ) => {
        setCart((prevCart) => {
            // Check if product already exists in cart
            const existingProductIndex = prevCart.findIndex(item => item.product_id === product_id);

            if (existingProductIndex !== -1) {
            // If product exists, create new array with updated quantity
            const updatedCart = [...prevCart];
            updatedCart[existingProductIndex].quantity += 1;
            localStorage.setItem("cart", JSON.stringify(updatedCart));
            return updatedCart;
            } else {
            // If product doesn't exist, add new product
            const cart = new Cart();
            cart.product_id = product_id;
            cart.name = name;
            cart.quantity = quantity;
            cart.price = price;
            cart.variant = variant;
            cart.variations.push(variations);
            cart.tax_total = tax_total;

            const data = cart.getCart();
            const newCart = [...prevCart, data];
            localStorage.setItem("cart", JSON.stringify(newCart));
            return newCart;
            }
        });
    };

    const addQuantity = (productId) => {
        console.log(productId);
        setCart((prevCart) => {
            const updatedCart = prevCart.map((item) => {
                if (item.product_id === productId) {
                    return { ...item, quantity: item.quantity + 1 };
                }
                return item;
            });
            localStorage.setItem("cart", JSON.stringify(updatedCart));
            return updatedCart;
        });
    };

    const minQuantity = (productId) => {
        setCart((prevCart) => {
            const targetItem = prevCart.find(item => item.product_id === productId);
            if (targetItem && targetItem.quantity <= 1) {
                const updatedCart = prevCart.filter(item => item.product_id !== productId);
                localStorage.setItem("cart", JSON.stringify(updatedCart));
                return updatedCart;
            }
            
            const updatedCart = prevCart.map((item) => {
                if (item.product_id === productId) {
                    return { ...item, quantity: item.quantity - 1 };
                }
                return item;
            });
            localStorage.setItem("cart", JSON.stringify(updatedCart));
            return updatedCart;
        });
    };

    const removeFromCart = (productId) => {
        setCart((prevCart) => {
            const updatedCart = prevCart.filter((item) => item.product_id !== productId);
            localStorage.setItem("cart", JSON.stringify(updatedCart));
            return updatedCart;
        });
    };


    // Fungsi untuk mengosongkan cart
    const clearCart = () => {
        setCart([]);
        // Hapus data cart dari localStorage
        localStorage.removeItem("cart");
    };

    // Saat komponen pertama kali di-mount, muat data cart dari localStorage
    useEffect(() => {
        const savedCart = localStorage.getItem("cart");
        if (savedCart) {
            setCart(JSON.parse(savedCart));
        }
    }, []);

    // Nilai yang akan disediakan ke context
    const value = {
        cart,
        loading,
        error,
        getCart,
        addToCart,
        removeFromCart,
        clearCart,  
        addQuantity,
        minQuantity,
    };

    return (
        <CartContext.Provider value={value}>
            {children}
        </CartContext.Provider>
    );
};

export default CartProvider;