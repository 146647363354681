const Voucher = () => {
    return(
        <>
            {/* Voucher Section */}
            <div className="voucher-section p-2 bg-white">
                <h3 className="voucher-title poppins-semibold text-lg mb-1">Voucher</h3>
                <div className="p-2 border-dashed border-2 border-indigo-300 rounded-lg bg-gradient-to-r from-orange-50 to-orange-200 mx-auto">
                <h3 className="text-lg poppins-regular font-semibold text-orange-900">
                    GERAKAN BERAMAL
                </h3>
                <p>Potongan Ongkir 25%</p>
                <p>Point Diskon Produk</p>
                </div>
            </div>
        </>
    )
}

export default Voucher;