import { Loader } from "../../../component/loader";
import { base_url, productImgPath } from "../../../config/init";
import { TextShimmerLoad } from "../../user/widget/TextShimmerLoad";

const OrderDetailCard = ({order, isLoading}) => {
    
    let productDetails;
    if (!order) {
        return <TextShimmerLoad />;
    }

    try {
        productDetails = typeof order.product_details === 'string' ? JSON.parse(order.product_details) : order.product_details;
    } catch (e) {
        console.error('Failed to parse product details:', e);
        productDetails = {};
    }

    return(
        <>
        {productDetails && productDetails.image && productDetails.name && productDetails.price ? (
          <div className="items-start flex">
          <div className="w-14 h-14 bg-gray-300 rounded-lg mr-2">
          <span className="text-gray-500">
            <img
            src={`${base_url}/${productImgPath}/${productDetails.image[0]}`}
            className="w-full h-full object-cover rounded-lg"
            />
          </span>
          </div>
          <div className="ml-2 flex flex-col justify-center">
          <span className="text-gray-700 text-sm poppins-light">{productDetails.name}</span>
          <span className="text-gray-500 text-sm poppins-light"> {parseFloat(order.price / productDetails.price).toFixed(2)}{productDetails.unit} * Rp {productDetails.price} = Rp{order.price}</span>
          </div>
        </div>
        ): (
          <TextShimmerLoad/>
        )}
        </>
    );
}

export default OrderDetailCard;