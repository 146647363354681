import AppBar from "../../../component/app_bar";
import { Content } from "../../../component/layout/content";

export default function CategoryView(){
    return (
       <>
       <AppBar title={'Categories'}/>
       <Content>
            <div>
                category view
            </div>
       </Content>
       </>
    )
}