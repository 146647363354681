import { useEffect, useState } from "react";
import { base_url } from "../../../config/init";
import { LoginProvider } from "../../../provider/LoginProvider";
import { useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory
import UserModel from "../../../models/UserModel";
import { BottomMenu } from "../../../component/bottom_menu";
import { Content } from "../../../component/layout/content";

const Login = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(''); // State to display error messages
    const navigate = useNavigate(); // Initialize useNavigate
    const userModel = new UserModel();

    const auth = async () => {
        const token = localStorage.getItem('authToken');
        try {
            if (!token) {
                const loginProvider = new LoginProvider();
                const token = await loginProvider.auth(phoneNumber, password);
                localStorage.setItem('authToken', token);
                userModel.setToken(token);
                navigate('/account');
            }else{
                navigate('/account');
            }
        } catch (error) {
            console.log(error);
            setError('Login failed. Please check your phone number and password.'); // Display error message
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            navigate('/account');
        }
    })

    return (
        <>
        <Content padding={false}>
        <div className="flex flex-col min-h-screen max-w-lg mx-auto bg-gray-50">
            {/* Image Box */}
            <div className="w-full h-56 bg-gray-300 flex items-center justify-center">
                {/* Replace with your image */}
                <img src={`${base_url}/public/assets/admin/css/img/auth-bg.png`} alt="Login" className="w-full h-full object-cover" />
            </div>

            {/* Form */}
            <div className="flex-grow flex flex-col justify-normal bg-gradient-to-r from-lime-50 to-lime-100 px-4">
                {/* Title */}
                <div className="mb-4 mt-4">
                    <h1 className="text-2xl poppins-regular mb-1">Login</h1>
                    <p className="poppins-light">Welcome to Torufarm</p>
                </div>

                {/* Error Message */}
                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                        <span className="block sm:inline">{error}</span>
                    </div>
                )}

                <div className="w-full max-w-md">
                    <div className="row">
                        <div className="col-6">
                            <div className="mb-4">
                                <input
                                    id="input-number"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    type="text"
                                    className="w-full p-2 border border-gray-300 text-center poppins-light rounded-md focus:outline-none focus:border-lime-500"
                                    placeholder="085xxxx"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <input
                                    id="input-password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    className="w-full p-2 border border-gray-300 text-center poppins-light rounded-md focus:outline-none focus:border-lime-500"
                                    placeholder="xxxx"
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <button onClick={auth} className="w-full text-white p-2 poppins-regular rounded-lg bg-lime-500 mb-4">
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BottomMenu/>
        </Content>
        
        </>
    );
};

export default Login;