import React, { useState } from "react";

import AppBar from "../../../component/app_bar";
import { Content } from "../../../component/layout/content";
import {BottomMenu} from '../../../component/bottom_menu';
import OrderProvider from "../../../provider/OrderProvider";
import OrderList from "../widget/OrderList";

function Orders(){
     const [activeChip, setActiveChip] = useState(0);
     const [status, setStatus]= useState('all');

    const chips = [
      { status: 'all', label: "Semua", icon: "📋" },
      { status: 'pending', label: "Pending", icon: "⌛" },
      { status: 'confirmed', label: "Konfirmasi", icon: "✅" },
      { status: 'packaging',label: "Packaging", icon: "📦" },
      { status: 'out_for_delivery', label: "Dijalan", icon: "🚚" },
      { status: 'completed', label: "Diterima", icon: "🎉" },
    ];

      const handleClickCategoryChips = (index) =>{
        setActiveChip(index)
      }

    return(
        <>
            <AppBar title={'Orders'}></AppBar>
            <Content>
                <div className="flex overflow-x-auto space-x-4 p-4 mb-1">
                    {chips.map((chip, index) => (
                        <div
                        key={index}
                        className={`flex items-center px-1 py-1 rounded-full border ${
                            activeChip === index
                            ? "bg-green-200 text-green-600 border-green-600"
                            : "bg-gray-100 text-gray-600 border-gray-300"
                        } cursor-pointer transition-colors duration-300`}
                        onClick={() => handleClickCategoryChips(index)}>

                        <small className="mr-2 text-sm">{chip.icon}</small>
                        <span>{chip.label}</span>
                        </div>
                    ))}
                </div>
               <OrderProvider>
                    <OrderList/>
               </OrderProvider>
               <BottomMenu/>
            </Content>
        </>
    )
}

export default Orders;