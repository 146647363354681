export const base_url = 'https://pos.torufarm.com'
export const all_products = '/api/v1/products/all';
export const product_details = '/api/v1/products/details';
export const flash_deal = '/api/v1/flash-deals';
export const banners = '/api/v1/banners';
export const users = '/api/v1/users';

//storage
export const productImgPath = '/storage/app/public/product';

// login
export const login = '/api/v1/auth/login';
export const check_phone = '/api/v1/auth/check-phone';
export const phone_verify = '/api/v1/auth/verify-phone';
export const firebase_auth = '/api/v1/auth/firebase-auth';

//customer
export const customerInfo = '/api/v1/customer/info';

//customer address
export const address = '/api/v1/customer/address';   

// customer order
export const orderList = '/api/v1/customer/order/list';
export const orderDetail = '/api/v1/customer/order/details';



//image
export const path_product_img = `${base_url}/storage/app/public/product`;

