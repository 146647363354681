import { useContext, useEffect} from "react";
import CartContext from "../../../context/CartContext";
import { not_found_img } from "../../../assets/assets";

const CartListItem = () => {
  const { cart, getCart, removeFromCart, addQuantity,minQuantity} = useContext(CartContext);

  
  useEffect(() => {
    getCart();
  }, []);

  const clearCartByInputSelected = () =>{
    //get all chexbox event true and clear the product
    const checkboxes = document.querySelectorAll('input[type="checkbox"]:checked');
    checkboxes.forEach((checkbox) => {
      const cartItem = checkbox.closest('.cart-item');
      if (cartItem) {
        const itemDetails = cartItem.querySelector('.item-details');
        const productName = itemDetails.querySelector('p').textContent;
        const product = cart.find(item => item.name === productName);
        if (product) {
          removeFromCart(product.product_id);
          // Uncheck all cart-item checkboxes
          const allCartCheckboxes = document.querySelectorAll('.cart-item input[type="checkbox"]');
          allCartCheckboxes.forEach(box => box.checked = false);
          // Also uncheck the select all checkbox
          const selectAllCheckbox = document.querySelector('.cart-items > div:first-child input[type="checkbox"]');
          if (selectAllCheckbox) selectAllCheckbox.checked = false;
        }
      }
    });
  }

  const activeAllCheckbox = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    const isFirstCheckboxChecked = checkboxes[0].checked;
    
    checkboxes.forEach((checkbox, index) => {
      if (index > 0) { // Skip the first checkbox
        checkbox.checked = isFirstCheckboxChecked;
      }
    });
  }

  return (
    <>
      <div className="cart-items mb-0">
        {cart != 0  && <div className="mt-1 mb-1 p-4 bg-white flex items-center justify-between">
            <div className="flex items-center">
              <input type="checkbox" className="mr-3 w-4 h-4" onClick={()=> activeAllCheckbox()}/>
              <div className="poppins-regular">Selected </div>
            </div>
            <i className="fa-solid fa-trash text-red-400" onClick={()=>clearCartByInputSelected()}></i>
        </div>}
        {cart != 0 && cart != null ? cart.map((item, index) => (
          <div key={index} className="cart-item bg-white p-4 mb-0 flex items-center">
            <input type="checkbox" className="mr-3 w-4 h-4" />
            <div className="w-16 h-16 bg-gray-400 mr-3 rounded"></div>
            <div className="item-details flex-1">
              <p className="font-bold">{item.name}</p>
              <p className="text-sm text-gray-600">Rp {item.price} &times; {item.quantity}</p>
            </div>
            <div className="item-actions flex items-center ml-auto bg-gray-50 rounded border-1 border-gray-300 overflow-hidden w-20 h-8 relative">
              <button className="text-red-500 w-10 h-full font-bold text-lg hover:bg-red-50" onClick={()=>minQuantity(item.product_id)}>
                -
              </button>
              <span className="flex-grow text-center text-lg font-semibold">
                {item.quantity}
              </span>
              <button onClick={()=>addQuantity(item.product_id)} className="text-green-500 w-10 h-full font-bold text-lg hover:bg-green-50">
                +
              </button>
            </div>
          </div>
        )): <div className="not-item text-center bg-white">
             <img src={not_found_img} className="" alt="No items found"/>
          </div>}
      </div>
    </>
  );
};

export default CartListItem;
