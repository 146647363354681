import { Content } from "../../../component/layout/content";
import AppBar from '../../../component/app_bar';

export default function Vouchers(props){
    return(
        <>
            <AppBar title={'Vourcher'}/>
            <Content>
                <div className="">
                    Voucher List
                </div>
            </Content>
        </>
    )
}