import React, { useContext, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { path_product_img } from '../config/init';
import CartContext from '../context/CartContext';

const ProductWidget = ({ products, type }) => {
    const {addToCart, getCart, removeFromCart} = useContext(CartContext);
    
    const navigate = useNavigate();
    const handleProductClick = (productId) => {
        navigate(`p/${productId}`);
    };

    const handleAddProduct = (product) => {
        //console.log(product);
       addToCart(
            product.id, //id
            product.name,
            product.price, //price input
            1,//quantiy 
            'Hello', //Variant
            [],//Variations
            0,//Tax_Amount
        );
    }

    const renderProductGrid = () => (
        <div className="product-list overflow-hidden">
            {products.map((product) => {
                const cartItem = getCart().find(item => item.id === product.id);
                
                return (
                    <div
                        key={product.id}
                        className="product-card mb-4"
                        data-product-id={product.id}
                        >
                        <div onClick={()=>handleProductClick(product.id)}>
                            <span type="hidden" product-id={product.id}></span>
                            <div className="product-image">
                                <img
                                    src={`${path_product_img}/${product.image[0]}`}
                                    alt={product.name}
                                    width="110"/>
                            </div>
                            <h5>{product.name}</h5>
                            <small>Rp {product.price}</small>
                            <p className="variant">{product.variant || ''}</p>
                        </div>

                        <div className="product-info">
                            {cartItem ? (
                                <div className="flex items-center gap-2">
                                    <button className="minus-btn bg-red-600 px-2" onClick={() => removeFromCart(product.id)}>-</button>
                                    <span>{cartItem.quantity}</span>
                                    <button className="add-btn bg-green-700 px-2" onClick={() => handleAddProduct(product)}>+</button>
                                </div>
                            ) : (
                                <button className="add-btn bg-green-700" onClick={() => handleAddProduct(product)}>+</button>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );

    const renderProductLine = () => (
        <div className="product-list">
            {products.map((product) => (
                <div
                    key={product.id}
                    className="product-card"
                    data-product-id={product.id}
                    onClick={() => handleProductClick(product.id)}
                >
                    <div className="product-image">
                        <img
                            src={`https://pos.torufarm.com/storage/app/public/product/${product.image[0]}`}
                            alt={product.name}
                            width="110"
                        />
                    </div>
                </div>
            ))}
        </div>
    );

    return type === 'grid' ? renderProductGrid() : renderProductLine();
};

export default ProductWidget;