import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import CartContext from "../context/CartContext";

export function BottomMenu() {
  const {cart} = useContext(CartContext);
  const location = useLocation(); // Mendapatkan informasi route saat ini
  return (
    <div className="fixed bottom-0 left-0 w-full max-w-lg mx-auto bg-white shadow-lg flex justify-around items-center py-4">
      {/* Home Icon */}
      <Link
        to="/"
        className={`flex flex-col items-center ${
          location.pathname === "/" ? "text-green-700" : "text-gray-600"
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className='h-6 w-6'
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
          />
        </svg>
        <span className="text-xs mt-1">Home</span>
      </Link>

      {/* Cart Icon */}
      <Link
        to="/cart"
        className={`flex flex-col items-center relative ${
          location.pathname === "/cart" ? "text-green-700" : "text-gray-600"
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>
        
          {cart.length && cart != null > 0 ?
            <div className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
            {cart.length}
          </div> 
          : ''
          }
        <span className="text-xs mt-1">Cart</span>
      </Link>

      {/* My Orders Icon */}
      <Link
        to="/orders"
        className={`flex flex-col items-center ${
          location.pathname === "/orders" ? "text-green-700" : "text-gray-600"
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
          />
        </svg>
        <span className="text-xs mt-1">My Orders</span>
      </Link>

      {/* Profile Icon */}
      <Link
        to="/account"
        className={`flex flex-col items-center ${
          location.pathname === "/account" ? "text-green-700" : "text-gray-600"
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
          />
        </svg>
        <span className="text-xs mt-1">Profile</span>
      </Link>
    </div>
  );
}