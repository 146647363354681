const TebusMurah = () => {
  return (
    <>
      {/* tebus murah section */}
      <div className="tebus-murah bg-green-50 mb-2">
        <div className="sale-sesction p-4">
          <h3 className="text-lg font-semibold text-green-700 poppins-light">
            Tebus Murah
          </h3>
        </div>
        <div className="flex space-x-2 overflow-x-auto pb-3">
          <div className="flex items-center ml-1 bg-white p-3 rounded-lg shadow-sm">
            <div className="w-16 h-16 bg-gray-400 mr-3 rounded"></div>
            <div>
              <p className="font-bold text-lime-900">Katombo</p>
              <p className="text-sm text-gray-600">Rp 18.000</p>
              <span className="text-xs bg-orange-50 text-orange-600 py-1 px-3 rounded">
                30%
              </span>
            </div>
            <button className="bg-green-200 text-green-500 py-1 px-3 rounded-lg ml-auto">
              +
            </button>
          </div>
          <div className="flex items-center bg-white p-3 rounded-lg shadow-sm">
            <div className="w-16 h-16 bg-gray-400 mr-3 rounded"></div>
            <div>
              <p className="font-bold text-lime-900">Katombo</p>
              <p className="text-sm text-gray-600">Rp 18.000</p>
              <span className="text-xs bg-orange-50 text-orange-600 py-1 px-3 rounded">
                30%
              </span>
            </div>
            <button className="bg-green-200 text-green-500 py-1 px-3 rounded-lg ml-auto">
              +
            </button>
          </div>
          <div className="flex items-center bg-white p-3 rounded-lg shadow-sm">
            <div className="w-16 h-16 bg-gray-400 mr-3 rounded"></div>
            <div>
              <p className="font-bold text-lime-900">Katombo</p>
              <p className="text-sm text-gray-600">Rp 18.000</p>
              <span className="text-xs bg-orange-50 text-orange-600 py-1 px-3 rounded">
                30%
              </span>
            </div>
            <button className="bg-green-200 text-green-500 py-1 px-3 rounded-lg ml-auto">
              +
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TebusMurah;
