// Loader.js
export function Loader() {
    return (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex items-center justify-center  bg-white p-4 z-50">
            <div className="loader-spinner border-4 border-green-500 border-t-transparent border-solid rounded-full w-12 h-12 animate-spin"></div>
        </div>
    );
}

export function Shimmer(){
    return ``;
}


export function LoaderMoreProducts(){
    return  (
        <div className="loading items-center justify-center flex">
            <div className="loader-spinner border-4 border-green-500 border-t-transparent border-solid rounded-full w-12 h-12 animate-spin"></div>
        </div>
    );
}



