import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppBar from '../../../component/app_bar';
import ProductProvider from '../../../provider/ProductProvider';
import { Loader } from '../../../component/loader';

const ProductDetails = () => {
    const { id } = useParams();
    const productProvider = new ProductProvider();

    const [product, setProduct] = useState(null);

    const loadProduct = async (id) => {
        try {
            const productModel = await productProvider.productDetails(id);
            const product = productModel.getProducts();
            if(product){
                setProduct(product);
            }
        } catch (error) {
            console.log('Something went wrong');
        }
    };

    useEffect(() => {
        loadProduct(id);
    }, [id]);

    if (!product) {
        return <Loader/>;
    }

    return (
        <div>
            <AppBar title={'Product Detail'} />
            <div className="bg-white max-w-lg mx-auto">
                <div className="product-image-detail">
                    <img
                        src={`https://pos.torufarm.com/storage/app/public/product/${product.image}`}
                        alt={product.name}
                        width="100%"
                    />
                </div>
                <div className="product-info p-2">
                    <h5>{product.name}</h5>
                    <small>Rp {product.price}</small>
                    <p className="variant">{product.variant}</p>
                </div>
            </div>
        </div>
    );
};

export default ProductDetails;