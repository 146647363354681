const OrderInfo = ({order}) => {
    return (
        <>
            <div>
                {order  ? (
                <div className='text-sm'>
                    {order ? order.branch_id : ''}
                </div>
                ):
                ('')}
            </div>
            <div>
                {/* Customer Info */}
                {order && order.delivery_address ? 
                    (
                    <div>
                        
                    </div>
                    ) : (
                    <div>
                        
                    </div>
                    )}
            </div>
    </>
    )
}

export default OrderInfo;