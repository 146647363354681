import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../views/home/screen/Home';
import CartView from '../views/cart/screen/CartView';
import Checkout from '../views/checkout/screen/checkout';
import Login from '../views/user/screen/Login';
import NotFound from '../component/404';
import ProductDetails from '../views/product/screen/ProductDetails';
import TaxseeOrderForm from '../views/delivery/MaximOrderForm';
import Account from '../views/user/screen/Account';
import Orders from '../views/orders/screen/Orders';
import AddressView from '../views/address/screen/AddressView';
import Vouchers from '../views/vouchers/screen/Vouchers';
import CategoryView from '../views/categories/screen/CategoryView';
import Help from '../views/help/screen/Help';
import PrivateRoute from './privateRoute';
import OrderDetails from '../views/orders/screen/OrderDetails';


 export default function Router(){
    return(
        <BrowserRouter>
        <Routes>
            <Route index element={<Home/>}></Route>
            <Route path='cart' element={<CartView/>}></Route>
            <Route path='checkout' element={<Checkout/>}></Route>
            <Route path='login' element={<Login/>}></Route>
    
            {/* Not Found */}
            <Route path='*' element={<NotFound/>}></Route>
    
            {/* Products */}
            <Route path='p/:id' element={<ProductDetails/>}></Route>
    
            {/* Delivery  */}
            <Route path='check-maxim' element={<TaxseeOrderForm/>}></Route>
    
            {/* Profile */}
            {/* <Route path='account' element={<Account/>}></Route> */}
            <Route element={<PrivateRoute />}>
              <Route path="/account" element={<Account />} />
            </Route>
    
            {/* Orders */}
            <Route element={<PrivateRoute />}>
              <Route path='/orders' element={<Orders/>}></Route>
              <Route path='/o/:id' element={<OrderDetails/>}></Route>
            </Route>
           
            {/* Address */}
            <Route path='address' element={<AddressView/>}></Route>
    
            {/* Voucher Discovery */}
            <Route path='voucher' element={<Vouchers/>}></Route>
    
            {/* Category */}
            <Route path='categories' element={<CategoryView/>}></Route>
    
            {/* Help */}
            <Route path='help' element={<Help/>}></Route>
        </Routes>
      </BrowserRouter>
    );
}