import React, { useState, useEffect, useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductProvider from '../../../provider/ProductProvider';
import Banner from '../widget/banner';
import { Loader, LoaderMoreProducts } from '../../../component/loader';
import FlashSaleTimer from '../widget/timer_view';
import ProductWidget from '../../../component/product_widget';
import { Link } from 'react-router-dom';
import { Content } from '../../../component/layout/content';
import {BottomMenu} from '../../../component/bottom_menu';

function Home() {
    const [products, setProducts] = useState([]); // State to hold products
    const [loading, setLoading] = useState(true); // State for loading indicator
    const [error, setError] = useState(null);    // State for error handling
    const [hasMore, setHasMore] = useState(true); // State to indicate if there are more products to load
    const [page, setPage] = useState(1);    
    const productProvider = new ProductProvider();

    const fetchProductsData = async (page) => {
        try {
            const params = { page, limit: 10 };
            const productModel = await productProvider.fetchProducts(params); // await the promise
            if (productModel && productModel.getProducts) { // Check if getProducts exists
                const fetchedProducts =
             productModel.getProducts();
                if (fetchedProducts.length === 0) {
                    setHasMore(false); // No more products to load
                } else {
                    setProducts([...products, ...fetchedProducts]);
                }
            } else {
                // Handle the case where productModel or getProducts is missing
                <Loader/>
            }
        } catch (error) {
            setError(error.message); // Set the error state
        } finally {
            setLoading(false); // Set loading to false regardless of success or failure
        }
    };

   

    useEffect(() => {
        fetchProductsData(page);
    }, [page]); // Add `page` as a dependency




    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
        <Content>
            <header className="mt-2">
                <div className="text-green-700">
                    <p className="poppins-light">Promo Live</p>
                    <h2 className="poppins-light text-2xl">Setiap Hari</h2>
                </div>
                <Link to='/account' className="profile-icon">
                    <i className="fa-solid fa-user text-green-800 text-2xl"></i>
                </Link>
            </header>

            <main id="main">
                <InfiniteScroll
                    dataLength={products.length}
                    next={() => {
                        const newPage = page + 1;
                        setPage(newPage);
                        fetchProductsData(newPage);
                    }}
                    hasMore={hasMore}
                    loader={<LoaderMoreProducts />}
                    endMessage={<p>No more products to load.</p>}>
                    <Banner />
                    <FlashSaleTimer />
                   
                        <ProductWidget products={products} type={'grid'} />
                </InfiniteScroll>
            </main>
            <BottomMenu/>   
        </Content>
        </>
    );
}

export default Home;