import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { post } from "../data/api";
import OrderDetailContext from "../context/OrderDetailContext";
import { orderDetail } from "../config/init";
import OrderContext from "../context/OrderContext";

const OrderDetailProvider = ({ children }) => {
    const { id } = useParams();
    const { orders } = useContext(OrderContext);

    const [orderDetails, setOrderDetails] = useState([]);
    const [orderInfo, setOrderInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchOrderDetails = async () => {
        setLoading(true);
        setError(null);

        try {
            const data = await post(`${orderDetail}`, { order_id: id });
            setOrderDetails(data);
        } catch (error) {
            console.error('Error fetching order details:', error);
            setError("Failed to fetch order details");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (orders && orders.length > 0 && id) {
            const foundOrder = orders.find(order => order.id === parseInt(id));
            setOrderInfo(foundOrder || null);
        }
    }, [orders, id]);

    useEffect(() => {
        if (id) {
            fetchOrderDetails();
        }
    }, [id]);

    const value = {
        orderDetails,
        orderInfo,
        loading,
        error,
    };

    return (
        <OrderDetailContext.Provider value={value}>
            {children}
        </OrderDetailContext.Provider>
    );
};

export default OrderDetailProvider;
