import React from 'react';
import '../../../assets/css/shimmer.css';
import { Link } from 'react-router-dom';

const OrderCard = ({ order, isLoading }) => {
    const statusColor = {
        pending: 'bg-yellow-400',      // Kuning untuk status pending
        canceled: 'bg-red-400',        // Merah untuk status canceled
        out_for_delivery: 'bg-blue-400', // Biru untuk status out_for_delivery
        packaging: 'bg-purple-400',    // Ungu untuk status packaging
        confirmed: 'bg-indigo-400',    // Indigo untuk status confirmed
        delivered: 'bg-green-400',     // Hijau untuk status delivered
      };
      
  if (isLoading) {
    return (
      <div className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition duration-300">
        {/* Shimmer untuk Order ID */}
        <div className="flex justify-between items-center mb-2">
          <div className="text-sm text-gray-600 shimmer w-24 h-4 rounded"></div>
          <div className="text-sm text-gray-500 shimmer w-16 h-4 rounded"></div>
        </div>

        {/* Shimmer untuk Produk */}
        <div className="flex space-x-2 overflow-x-auto mb-2">
          {[1, 2].map((_, index) => (
            <div
              key={index}
              className="w-14 h-14 bg-gray-300 rounded-lg flex-shrink-0 shimmer"
            ></div>
          ))}
        </div>

        {/* Shimmer untuk Total */}
        <div className="mb-2">
          <div className="text-sm text-gray-600 shimmer w-20 h-4 rounded"></div>
        </div>
      </div>
    );
  }

  return (
    <Link to={`/o/${order.id}`}>
    <div className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition duration-300">
      <div className="flex justify-between items-center mb-2">
        <div className="text-sm text-gray-600">
          <span className="font-semibold">Order ID:</span> {order.id}
        </div>
        <div className="text-sm text-gray-500">
        <div className={`${statusColor[order.order_status] || 'bg-gray-400'} px-2 py-1 rounded-full text-black text-sm`}>
                {order.order_status}
        </div>
        </div>
      </div>

      <div className="flex space-x-2 overflow-x-auto mb-2">
        {order.products && order.products.length > 0 ? (
          order.products.map((product, index) => (
            <div
              key={index}
              className="w-48 h-48 bg-gray-300 rounded-lg flex-shrink-0"
            >
              <img
                src={product.image}
                alt={product.name}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          ))
        ) : (
          <>
            <div className="w-14 h-14 bg-gray-300 rounded-lg flex-shrink-0 flex items-center justify-center">
              <span className="text-gray-500"></span>
            </div>
            <div className="w-14 h-14 bg-gray-300 rounded-lg flex-shrink-0 flex items-center justify-center">
              <span className="text-gray-500"></span>
            </div>
          </>
        )}
      </div>

      <div className="mb-2">
        <div className="text-sm text-gray-600">
          <span className="font-semibold">Total:</span> Rp{order.order_amount}
        </div>
      </div>
    </div>
    </Link>
  );
};

export default OrderCard;