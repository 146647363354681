import AppBar from "../../../component/app_bar";
import { Content } from "../../../component/layout/content";
import OrderDetailProvider from "../../../provider/OrderDetailsProvider";
import OrderProvider from "../../../provider/OrderProvider";
import Details from '../../orders/widget/Details';
import OrderInfo from "../widget/OrderInfo";


const  OrderDetails = () => {
  
    return(
        <>
        <AppBar title={'detail'}/>
         <Content>
            <OrderProvider>
                <OrderDetailProvider>
                    <OrderInfo/>
                    <Details/>
                </OrderDetailProvider>
            </OrderProvider>
         </Content>
        </>
    )
}

export default OrderDetails;