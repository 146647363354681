import React, { useContext } from "react";
import OrderContext from "../../../context/OrderContext";
import OrderCard from "./OrderCard";


const OrderList = () => {
  const { orders, isLoading } = useContext(OrderContext); 
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {orders.length === 0 && !isLoading  
        ?
          Array.from({ length: 5}).map((_, index) => (
            <OrderCard key={index} isLoading={true} />
          ))
        : 
          orders.map((order) => (
            <OrderCard key={order.id} order={order} isLoading={false} />
          ))}
    </div>
  );
};

export default OrderList;