
import AppBar from "../../../component/app_bar";
import { Content } from "../../../component/layout/content";
import MyAddressList from "./MyAddressList";

export default function AddressView(){
    return(
        <>
        <AppBar title={'Address'}/>
        <Content>
            <div className="flex flex-col space-y-4">
            <input
                type="text"
                placeholder="Search address"
                className="border border-gray-400 p-2 rounded-full"
            />
            <MyAddressList/>
            </div>
        </Content>
        </>
        )   
}