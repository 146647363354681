import CartProvider from "../../views/cart/provider/CartProvider";

export  function Content({ padding = true, children }) {
    return (
      <div className={`h-full max-w-lg mx-auto bg-white ${padding ? 'p-4' : ''}`}>
        <CartProvider>
          {children}
        </CartProvider>
      </div>
    );
  }