class OrderModel {
  constructor() {
    this.orders = [];
  }

  createOrder(order) {
    this.orders.push(order);
    return order;
  }

  getOrders() {
    return this.orders;
  }

}


export class Cart{
  constructor(){
    this.product_id = '';
    this.name = '';
    this.price = 0;
    this.quantity = 0;
    this.variant = [];
    this.variations = [];
    this.tax_total = 0;
  }

  setVariant(variant){
    this.variant = variant;
  }

  getVariant(){
    return this.variant;
  }

  setVariations(variation){
    this.variations = variation;
  }

  getVariations(){
    return this.variations;
  }

  getCart(){
    return {
      product_id : this.product_id,
      name: this.name,
      price : this.price,
      quantity : this.quantity,
      variant : this.variant,
      variations: this.variations,
      tax_total : this.tax_total,
    }
  }
}


class PlaceOrder{
  constructor () {
     this.cart = [
        
     ]
  }
}





