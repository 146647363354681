import  {get}  from "../data/api.js";
import  {all_products, product_details}  from "../config/init";
import { ProductModel } from "../models/ProductModel";

class ProductProvider {
    uri = '';
    async fetchProducts(params){
       
        try {
            if(!params){
                this.uri = all_products;
            }else{
                this.uri = `${all_products}?page=${params.page}&limit=${params.limit}`
            }
            const response = await get(this.uri);
            const productsModel = new ProductModel();
            productsModel.setProducts(response.products);
            return productsModel;
        } catch (error) {
            console.log(error);
        }
    }

    async productDetails(id){
        try {
            const response = await get(`${product_details}/${id}`);
            const productsModel = new ProductModel();
            productsModel.setProducts(response);
            console.log(response);
            return productsModel;
        } catch (error) {
            console.log(error);
        }
    }
}

  

export default ProductProvider;