import { base_url } from "../config/init.jsx";

/**
 * Fungsi untuk membuat request API dengan fetch
 * @param {string} endpoint - Endpoint API (misalnya '/products')
 * @param {string} method - HTTP Method (GET, POST, PUT, DELETE)
 * @param {Object} [data] - Data yang dikirim dalam body request
 * @returns {Promise<Object>} - Response dari API
 */
export async function apiRequest(endpoint, method = 'GET', data = null) {
    const options = {
        method,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Localization': 'en'
        },
    };

    // Ambil token dari localStorage
    const token = localStorage.getItem('authToken');

    // Tambahkan token ke header jika ditemukan
    if (token) {
        options.headers['Authorization'] = `Bearer ${token}`;
    }

    // Tambahkan body ke request jika data disediakan
    if (data) {
        options.body = JSON.stringify(data);
    }

    const response = await fetch(`${base_url}${endpoint}`, options);

    if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    return response.json();
}

/** Shortcut untuk GET request */
export function get(endpoint) {
    return apiRequest(endpoint, 'GET');
}

/** Shortcut untuk POST request */
export function post(endpoint, data) {
    return apiRequest(endpoint, 'POST', data);
}

/** Shortcut untuk PUT request */
export function put(endpoint, data) {
    return apiRequest(endpoint, 'PUT', data);
}

/** Shortcut untuk DELETE request */
export function remove(endpoint) {
    return apiRequest(endpoint, 'DELETE');
}