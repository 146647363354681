import React, { useEffect } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import { BlankBottomSheet } from '../../component/notifications';

const TaxseeOrderForm = () => {
    return (
        <div className="app-order-block bg-black fixed bottom-0 left-0 right-0 bg-grey-50 p-4 max-w-lg mx-auto poppins-regular">
        <div className='text-center grid place-items-center mb-3'>
            <div className='logo bg-white mb-2 rounded-xl'>
                <img  src='https://id.taximaxim.com/images/logo_nr.svg' alt="Maxim Logo" />
            </div>
            <small className='poppins-light  text-white'>Form ini di sediakan oleh id.taximaxim.com</small>
        </div>
        <div id="frame-order">
            <iframe
                className='text-lime-800'
                name="order-form-frame"
                id="order-form-frame"
                frameBorder="0"
                tabIndex="1"
                width="100%"
                height="613.125px"
                scrolling="no"
                src="https://client-id.taximaxim.com/frame/?tax-id=yFL33BWu8yOEhqH0C0bV8BfGWKFjFba7Sxdwcdfppe71sHd4uxidkbS5%2B%2BYzBsW%2BiAH1yXFh2Na5bJdvZaNNTNRa6w%2BY1xpwqd1XUGEIcJc%3D&c=id&l=id-ID&b=7707&p=1&theme=maximV3"
            />
        </div>
    </div>
    );
  };

  export default TaxseeOrderForm;