// helpers.js
import {LoaderHomeProducts, Loader, LoaderMoreProducts} from "./loader";

export function showLoader() {
    document.body.innerHTML += Loader(); // Tambahkan loader ke body
}

export function hideLoader() {
    const loader = document.querySelector('.loader-container');
    if (loader) loader.remove(); // Hapus loader jika ada
}


export function showLoadProduct(element){
   return(
    <LoaderMoreProducts/>
   )
}

export function hideLoadProduct(element){
    const load = element.querySelector('.loading');
    load.remove();
}


export function isNumberOrEmail(value){
    return value.match(/^\d+$/) ? 'phone' : 'email';
}
