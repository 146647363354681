import { Link, useNavigate } from "react-router-dom";
import AppBar from "../../../component/app_bar";
import { Content } from "../../../component/layout/content";
import { BottomMenu } from "../../../component/bottom_menu";
import { CustomerProvider } from "../../../provider/CustomerProvider";
import { useEffect, useState } from "react";
import { TextShimmerLoad } from "../widget/TextShimmerLoad";

function Account() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/login');
  };

  const getCustomerInfo = async () => {
    try {
      const customerProvider = new CustomerProvider();
      const user = await customerProvider.getCustomer();
      if (user) {
        setUser(user.getUser()); // Simpan data pengguna ke state
      }
    } catch (error) {
      console.error('Get customer info error:', error.message);
    }
  };


  useEffect(() => {
    getCustomerInfo();
  }, []);

  return (
    <>
      <AppBar title={'Account'} />
      <Content padding={false}>
        <div className="bg-gradient-to-r from-green-400 to-lime-500 p-6">
          <span className="mb-6">
            <h2 className="text-xl poppins-light">{user ? user.f_name + ' ' + user.l_name : <TextShimmerLoad/>}</h2>
            <h3 className="text-sm poppins-light">{user ? user.email : <TextShimmerLoad/>}</h3>
          </span>

          <div className="grid grid-cols-2 gap-4 mt-2">
            <div className="bg-gradient-to-r from-lime-50 to-lime-100 p-2 rounded-lg flex flex-col justify-center">
              <span className="poppins-medium mb-2">Dompet</span>
              <span className="flex">
                <i className="fa-solid fa-wallet text-green-800 text-sm mr-2"></i>
                <span className="text-green-800 poppins-light">Rp { user ? user.wallet_balance: '...'} </span>
              </span>
            </div>
            <div className="bg-gradient-to-r from-orange-50 to-orange-100 p-2 rounded-lg flex flex-col justify-center">
              <span className="poppins-medium mb-2">Point</span>
              <span className="flex">
                <i className="fa-solid fa-bullseye text-yellow-500 text-sm mr-2"></i>
                <span className="text-green-800 poppins-light">{user ? user.loyalty_point : '...'}</span>
              </span>
            </div>
          </div>
        </div>
        
        
        <div className="bg-white ps-6 pe-6 pt-2 mt-4">
          {/* Orders */}
          <Link to={'/orders'}>
            <span className="flex items-center border-b border-b-gray-300 pb-3">
              <div className="border-solid rounded-full p-1">
                <i className="fa-solid fa-clipboard text-green-700" aria-label="Gift"></i>
              </div>
              <div className="ml-3 poppins-light flex-1">
                Pesanan
                <span className="block">
                  <small className="text-sm text-gray-400 poppins-light">Kelola pesanan</small>
                </span>
              </div>
              <div className="ml-auto">
                <i className="fa-solid fa-arrow-right text-gray-500" aria-label="Right Arrow"></i>
              </div>
            </span>
          </Link>

          {/* Alamat */}
          <Link to={'/address'}>
            <span className="flex items-center border-b border-b-gray-300 mt-2 pb-3">
              <div className="border-solid rounded-full p-1">
                <i className="fa-solid fa-location-dot text-green-700" aria-label="Location"></i>
              </div>
              <div className="ml-3 poppins-light flex-1">
                Alamat
                <span className="block">
                  <small className="text-sm text-gray-400 poppins-light">Kelola alamat pengiriman</small>
                </span>
              </div>
              <div className="ml-auto">
                <i className="fa-solid fa-arrow-right text-gray-500" aria-label="Right Arrow"></i>
              </div>
            </span>
          </Link>

          {/* Voucher */}
          <Link to={'/voucher'}>
            <span className="flex items-center border-b border-b-gray-300 mt-2 pb-3">
              <div className="border-solid rounded-full p-1">
                <i className="fa-solid fa-gift text-green-700" aria-label="Help"></i>
              </div>
              <div className="ml-3 poppins-light flex-1">
                Voucher Saya
                <span className="block">
                  <small className="text-sm text-gray-400 poppins-light">Voucher untuk saya</small>
                </span>
              </div>
              <div className="ml-auto">
                <i className="fa-solid fa-arrow-right text-gray-500" aria-label="Right Arrow"></i>
              </div>
            </span>
          </Link>

          {/* Logout */}
          <span className="flex items-center mt-2 pb-3" onClick={handleLogout}>
            <div className="border-solid rounded-full p-1">
              <i className="fa-solid fa-arrow-right-from-bracket text-gray-500" aria-label="Help"></i>
            </div>
            <div className="ml-3 poppins-light flex-1">
              Logout
            </div>
          </span>
        </div>
        <BottomMenu />
      </Content>
    </>
  );
}

export default Account;