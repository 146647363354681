import React, { useState, useEffect } from 'react';
import OrderContext from '../context/OrderContext';
import { get } from '../data/api';
import { orderList } from '../config/init';

const OrderProvider = ({ children }) => {
    // State untuk menyimpan daftar pesanan
    const [orders, setOrders] = useState([]);
    // State untuk menangani loading state
    const [loading, setLoading] = useState(false);
    // State untuk menangani error
    const [error, setError] = useState(null);

    // Fungsi untuk mengambil daftar pesanan dari API
    const fetchOrders = async () => {
        setLoading(true); // Set loading ke true saat fetching dimulai
        setError(null); // Reset error state
        try {
            const data = await get(orderList); // Ambil data dari API
            setOrders(data); // Update state orders dengan data yang diterima
        } catch (error) {
            console.error('Error fetching orders:', error);
            setError("Failed to fetch orders"); // Set error state jika terjadi error
        } finally {
            setLoading(false); // Set loading ke false setelah fetching selesai
        }
    };


    // Fungsi untuk menambahkan pesanan baru ke state
    const addOrder = (order) => {
        setOrders((prevOrders) => [...prevOrders, order]);
    };

    // Gunakan useEffect untuk fetch data saat component pertama kali mount
    useEffect(() => {
        fetchOrders();
    }, []); // Dependency array kosong agar hanya dijalankan sekali

    // Value yang akan disediakan ke context
    const value = {
        orders,
        loading,
        error,
        addOrder,
    };

    return (
        <OrderContext.Provider value={value}>
            {children}
        </OrderContext.Provider>
    );
};

export default OrderProvider;