import { Link } from "react-router-dom";
import AppBar from "../../../component/app_bar";
import CartProvider from "../provider/CartProvider";
import CartListItem from "../widget/CartListItem";
import TebusMurah from "../widget/TebusMurah";
import Voucher from "../widget/Voucher";
import OrderType from "../widget/OrderType";
import { Content } from "../../../component/layout/content";

const CartView = () => {
  return (
    <div>
      <div className="max-w-lg mx-auto bg-gray-50">
        <AppBar title={"Keranjang"} />
        <Content padding={false}>
          <CartProvider>
            <CartListItem />
            <TebusMurah/>
            <Voucher/>
            <OrderType/>
          </CartProvider>
        </Content>
      </div> 
    </div>
  );
};

export default CartView;
