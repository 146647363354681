import React from 'react';

const MyAddressList = () => {
    const addresses = [
        { id: 1, name: 'Home', address: '123 Main St, Anytown, USA' },
        { id: 2, name: 'Work', address: '456 Office Rd, Bigcity, USA' },
        // Add more addresses as needed
    ];

    return (
        <div>
            <ul>
                {addresses.map((address) => (
                    <li key={address.id} className="card p-2 rounded-md border-2 border-gray-200 mb-2">
                        <h2>{address.name}</h2>
                        <p>{address.address}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default MyAddressList;