import React, {useContext} from 'react';
import OrderDetailContext from '../../../context/OrderDetailContext';
import OrderDetailCard from './OrderDetailCard';
import OrderInfo from './OrderInfo';
import OrderContext from '../../../context/OrderContext';
import { useParams } from 'react-router-dom';
import OrderDetailProvider from '../../../provider/OrderDetailsProvider';

const Details = () => {
    const {orderDetails, orderInfo, isLoading} = useContext(OrderDetailContext);
    console.log(orderInfo)
    return(
        <div className="flex flex-col gap-4">
            <OrderInfo order={orderInfo}/>
            {orderDetails.length === 0 && !isLoading
            ? Array.from({ length: 2 }).map((_, index) => (
                <OrderDetailCard key={index} isLoading={true} />
            ))
            : orderDetails.map((order) => (
                <OrderDetailCard key={order.id} order={order} isLoading={false} />
            ))}
        </div>
    )
}

export default Details;