const OrderType = () => {
    return(
        <>
          {/* Select Order Type */}
          <div className="order-type p-2 mb-6">
            <h3 className="text-lg font-semibold mb-2">Jenis Pemesanan</h3>
            <div className="space-y-2">
              <label className="block">
                <input type="radio" name="order-type" className="mr-2" /> Pengiriman
              </label>
              <label className="block">
                <input type="radio" name="order-type" className="mr-2" /> Ambil Di
                Toko
              </label>
            </div>
          </div>
        </>
    )
}

export default OrderType;