export default function FlashSaleTimer(){
    return (
        <div className="promo-timer flex justify-between items-center bg-gradient-to-r from-green-500 to-green-700 p-6 rounded-lg  mt-2">
            <div className="promo-text text-white text-xl font-semibold tracking-wide">
                <span>Promo Live</span>
            </div>
            <div className="timer text-3xl font-mono text-white flex space-x-2">
                <span className="bg-amber-100  px-4 py-2 text-green-700 rounded-lg">02</span>
                <span className="text-white">:</span>
                <span className="bg-amber-100  px-4 py-2  text-green-700 rounded-lg">53</span>
                <span className="text-white">:</span>
                <span className="bg-amber-100  px-4 py-2  text-green-700 rounded-lg">23</span>
            </div> 
        </div>
    );
}