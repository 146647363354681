import id from "../../../assets/language/id";
import AppBar from "../../../component/app_bar";
import React, { useState } from 'react';
import { BlankBottomSheet, BottomSheet } from "../../../component/notifications";
import TaxseeOrderForm from "../../delivery/MaximOrderForm";

const   Checkout = () => {
  
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const [bottomSheetType, setBottomSheetType] = useState('');
    const [bottomSheetMessage, setBottomSheetMessage] = useState('');

    const openBottomSheet = (type, message) => {
        setBottomSheetType(type);
        setBottomSheetMessage(message);
        setIsBottomSheetOpen(true);
    };

    const closeBottomSheet = () => {
        setIsBottomSheetOpen(false);
    };

    return (
        <div className="max-w-lg mx-auto bg-gray-50">
            
            <AppBar title={id.checkout}/>

            <div className="overflow-y-auto" style={{ maxHeight: 'calc(100vh - 100px)' }}>
                <div className="cart-item bg-white p-4 mb-2 flex flex-col items-start relative">
                    <div className="flex items-center w-full">
                        <i className="fa-solid fa-location-dot text-yellow-400 self-start text-lg"></i>
                        <h2 className="ml-2 poppins-regular">Rumah</h2>
                        <i className="fa-solid fa-edit absolute top-2 right-2 text-lime-900"></i>
                    </div>
                    <small className="ml-6 poppins-regular">Jhon | 084304</small>
                    <p className="ml-6 poppins-regular mt-2 text-sm">jln terusan hang lekir no 40,</p>
                </div>

                <div className="bg-white p-4 mb-2">
                    <h3>Items</h3>
                    <div className="cart-item bg-white flex items-center">
                        <input type="checkbox" className="mr-3" />
                        <div className="w-16 h-16 bg-gray-400 mr-3 rounded"></div>
                        <div className="item-details flex-1">
                            <p className="font-bold">Ikan Katombo</p>
                            <p className="text-sm text-gray-600">Rp 23.000 &times; 2</p>
                            <span className="text-xs bg-orange-500 text-white py-1 px-2 rounded">Big Sale</span>
                        </div>
                    </div>

                    <div className="cart-item bg-white mb-0 flex items-center">
                        <input type="checkbox" className="mr-3" />
                        <div className="w-16 h-16 bg-gray-400 mr-3 rounded"></div>
                        <div className="item-details flex-1">
                            <p className="font-bold">Ikan Katombo</p>
                            <p className="text-sm text-gray-600">Rp 23.000 &times; 2</p>
                            <span className="text-xs bg-orange-500 text-white py-1 px-2 rounded">Big Sale</span>
                        </div>
                    </div>
                </div>

                <div className="delivery-time-date bg-white p-4">
                    <h3 className="font-bold mb-4">Columns</h3>
                    <div className="flex space-x-2">
                        <label className="flex items-center space-x-2 cursor-pointer opacity-50">
                            <input type="radio" name="custom-radio" className="hidden peer" disabled />
                            <div className="w-20 h-20 bg-white-100 border border-gray-300 p-2 rounded-lg justify-center peer-checked:bg-lime-50 peer-checked:border-lime-900 peer-focus:bg-lime-50 peer-focus:border-lime-900">
                                <span className="text-gray-700 peer-checked:text-lime-900 poppins-bold p-0 m-0 poppins-light">Hari Ini</span>
                                <p className="text-xs/[18px] text-gray-700 peer-checked:text-lime-900 poppins-light">29,Nov 2024</p>
                            </div>
                        </label>

                        <label className="flex items-center space-x-2 cursor-pointer">
                            <input type="radio" name="custom-radio" className="hidden peer" />
                            <div className="w-20 h-20 bg-white-100 border border-gray-300 p-2 rounded-lg justify-center peer-checked:bg-lime-50 peer-checked:border-lime-900 peer-focus:bg-lime-50 peer-focus:border-lime-900">
                                <span className="text-gray-700 peer-checked:text-lime-900 peer-checked:font-bold p-0 m-0 poppins-light">Besok</span>
                                <p className="text-xs/[18px] text-gray-700 peer-checked:text-lime-900 poppins-light">01,Des 2024</p>
                            </div>
                        </label>

                        <label className="flex items-center space-x-2 cursor-pointer">
                            <input type="radio" name="custom-radio" className="hidden peer" />
                            <div className="w-20 h-20 bg-white-100 border border-gray-300 p-2 rounded-lg justify-center peer-checked:bg-lime-50 peer-checked:border-lime-900 peer-focus:bg-lime-50 peer-focus:border-lime-900">
                                <span className="text-gray-700 peer-checked:text-lime-900 p-0 m-0 poppins-light">Lusa</span>
                                <p className="text-xs/[18px] text-gray-700 peer-checked:text-lime-900 poppins-light">02,Des 2024</p>
                            </div>
                        </label>
                    </div>
                </div>
            </div>

            <div className="fixed bottom-0 left-0 right-0 bg-grey-50 p-4 max-w-lg mx-auto poppins-regular">
                <div className="flex justify-between mb-2">
                    <p className="text-gray-700">Ongkos Kirim</p>
                    <p className="text-right text-gray-700">Rp 8.000</p>
                </div>
                <div className="btn-checkout">
                    <button className="bg-lime-400 p-3 w-full rounded-full" onClick={()=> openBottomSheet('error','Berhasil Membuat Aplikasi')}>Buat Pesanan</button>
                </div>

                <BlankBottomSheet
                    isOpen={isBottomSheetOpen}
                    onClose={closeBottomSheet}
                    type={bottomSheetType}
                    message={bottomSheetMessage}>
                       
                    </BlankBottomSheet>
            </div>
            
        </div>
    );
};

export default Checkout;